import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN INDIANA' activeCard='indiana' image={'/Indiana_BG.jpeg'}>
          <p>
            We post often about news in the health care industry and help consumers make informed decisions when it comes to their health and health insurance
            in Indiana. If you’re looking around for health insurance quotes in Indiana, you can receive one right on our website. One of our experts will help
            you find the best and most affordable health insurance in Indiana for you!
          </p>
          <p>
            We help all our clients get the best coverage possible in Indiana at the most affordable price. We are experts in this industry and do whatever we
            can to be your helpful, trusted, and knowledgeable adviser, all while keeping you up to date.
          </p>
          <p></p>
          We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and out
          of pocket expenses of health insurance in Missouri shifting to the consumer, gap insurance plans have become the most important and popular plan we
          sell today.
          <p>
            We offer every solution available in your market to give you the highest quality insurance at the lowest possible price. Apollo provides education,
            options and the best pricing available in your market. We look forward to working with you!
          </p>
        </Content>
      </div>
    </Layout>
  );
};
